<template>
  <div class="trip-summary bdbr-3 bd-radius d-flex align-items-center justify-content-between">
    <div class="">
      <img v-if="summary_data.dealLength!='O/W'" :src="'https://images.kiwi.com/airlines/64x64/'+summary_data.flightsPairs[0].from.airline+'.png'" alt="" class="d-block company-logo">
      <img :src="'https://images.kiwi.com/airlines/64x64/'+summary_data.flightsPairs[0].to.airline+'.png'" alt="" class="d-block company-logo mt-5">
    </div>
    <div class="mx-5 fs16">
      Tel_Aviv, Israel
    </div>
    <div class="mx-5 text-center fs11">
      <p v-if="summary_data.dealLength=='O/W'" class="red--text fs10 mb-0">{{ $t( "One Way" ) }}</p>
      <p class="mb-0" v-else>{{ $t("Return") }}</p>
      <img v-if="summary_data.dealLength=='O/W'" src="/assets/img/icons/arrows-h-alt-red.svg" alt="">
      <img v-else src="/assets/img/icons/flight.svg" alt="">
      <p v-if="summary_data.dealLength!='O/W'">{{ summary_data.dealLength }} Days</p>
    </div>
    <div class="mx-5 fs16">
      {{ summary_data.destinationName }}, {{ summary_data.destinationCountry }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TripSummary2",
  props: {
    summary_data: {
      type: Object,
      required: true,
      default: {}
    },
  },
}
</script>

<style lang="scss" scoped>
  .trip-summary {
    padding: 5px 10px;
  }

  @media (max-width: 670px) {
    .trip-summary {
      flex-direction: column;
    }
  }
</style>
<template>
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="df-c p-relative tourism-category__place">
      <img
        class="tourism-category__image"
        :src="generateDealImagePath(deal) || '/assets/img/rome2.png'"
        alt
      />
      <!-- <h3 class="tourism-category__place-name fs23">{{ deal.destinationName }}</h3> -->
      <div v-if="deal.destinationName && deal.destinationCountry" class="yellow darken-4 px-4 py-1 white--text country-city_tag fs14 font-weight-bold">
        {{ deal.destinationName }}, {{ deal.destinationCountry }}
      </div>
      <CategoryShare 
        :dealId="deal.dealId"
        :rowId="deal.rowId" />
      <CategoryStar></CategoryStar>
    </div>
    <div class="card-body bg-white pb-2">
      <slot name="title"></slot>
      <FlightDesData :deal="deal">
        <template slot="additionalData">
          <slot name="actionPart"></slot>
        </template>
      </FlightDesData>
      
      <div class="d-flex flex-column align-items-center">
        <div class="mt-10 fs14 text-center">
          {{ $t( "Starting from" ) }}: <b class="fs16"> {{ $t(deal.currency) }} {{ deal.price }}</b>
        </div>
        <slot name="routerBlock"></slot>
        <p class="fs11 mt-2">
          * {{ $t( "The price is for the specific flight shown" ) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../../RateStar';
import CategoryShare from '../../CategoryShare';
import CategoryStar from '../CategoryStar';
import FlightDesData from '../FlightDesData';
import tourismMixin from '@/utility/tourism_mixin'
export default {
  name: "FlightTourismItem",
  mixins: [tourismMixin],
  props: {
    deal: {
      type: Object
    }
  },
  components: {
    RateStar,
    CategoryShare,
    CategoryStar,
    FlightDesData,
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .country-city_tag {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
</style>
export default {
  methods: {
    generateDealImagePath(deal) {
      if (!deal || (!deal.HotelImages && !deal.sygic_media)) {
        return false;
      }

      let imagePath = null;
      if (deal.HotelImages && Array.isArray(deal.HotelImages)) {
        let hotelImageData = deal.HotelImages.find((hotelImage) => {
          return (
            hotelImage.ImageType && hotelImage.ImageType.toLowerCase() == 'main'
          );
        });
        if (!hotelImageData) {
          hotelImageData = deal.HotelImages[0];
        }
        if (hotelImageData) {
          imagePath = hotelImageData.Url;
        }
      }

      if (imagePath == null && deal.sygic_media) {
        if (!Array.isArray(deal.sygic_media)) {
          deal.sygic_media = JSON.parse(deal.sygic_media);
        }
        if (Array.isArray(deal.sygic_media)) {
          let destImageData = deal.sygic_media.find((destImage) => {
            return destImage.type && destImage.type.toLowerCase() == 'photo';
          });
          if (destImageData) {
            let imageSize = '358x202';
            imagePath = destImageData.url_template.replace('{size}', imageSize);
          }
        }
      }

      return imagePath;
    },
    sortDeals({type, isTourism}) {
      if (type == 'asc') {
        function compare(a, b) {
          if (a.dealPricePerPerson < b.dealPricePerPerson)
            return -1;
          if (a.dealPricePerPerson > b.dealPricePerPerson)
            return 1;
          return 0;
        }
        if (isTourism) {
          this.sortedTourismDeals.items = this.sortedTourismDeals.items.sort(compare);
        } else {
          this.oneTypeCheapestDeals = this.oneTypeCheapestDeals.sort(compare);
        }
      } else if (type == 'desc') {
        function compare(a, b) {
          if (a.dealPricePerPerson < b.dealPricePerPerson)
            return 1;
          if (a.dealPricePerPerson > b.dealPricePerPerson)
            return -1;
          return 0;
        }
        if (isTourism) {
          this.sortedTourismDeals.items = this.sortedTourismDeals.items.sort(compare);
        } else {
          this.oneTypeCheapestDeals = this.oneTypeCheapestDeals.sort(compare);
        }
      } else {
        function compare(a, b) {
          if (a.departureDate < b.departureDate)
            return -1;
          if (a.departureDate > b.departureDate)
            return 1;
          return 0;
        }
        if (isTourism) {
          this.sortedTourismDeals.items = this.sortedTourismDeals.items.sort(compare);
        } else {
          this.oneTypeCheapestDeals = this.oneTypeCheapestDeals.sort(compare);
        }
      }
    },
  },
}

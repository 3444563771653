<template>
    <section class="hotel tourism-product__hotel @@hotel footer-border-del">
      <button 
        class="button hotel__top-btn" 
        @click="toggleHotel()"
        :class="showHotel ? 'rotate-minus-90': 'rotate-chevron'"
      >
        <h2
          class="title title__h2 hotel__title"
        >Hilton Hotel Barcelona</h2>
        <RateStar :starNumber="5"></RateStar>
      </button>

      <div 
        class="hotel__wide-cover"
        v-show="showHotel"
      >
        <div class="hotel__cover">
          <div class="hotel__container">
            <p
              class="text hotel__text"
            >test</p>

            <div class="hotel__facilities">
              <h3
                class="title title__h3 hotel__title-h3"
              >{{ $t('Facilities') }}</h3>
              <div class="hotel__wrapper">
                <div
                  class="facility"
                >
                  <img src="/assets/img/icons/$.svg" alt />
                  <span>test</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import RateStar from "../../RateStar.vue"
export default {
  name: "HotelData",
  components: {
    RateStar
  },
  data: () => {
    return {
      showHotel: true,
    }
  },
  methods: {
    toggleHotel() {
     this.showHotel = !this.showHotel
    },
  }
}
</script>

<style lang="scss" scoped>
  .tourism-product__hotel {
    margin-top: 0;
    .facility {
      width: 33%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        margin: 0 10px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .hotel::before {
    content: none;
  }
</style>

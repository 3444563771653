<template>
   <modal
    :width="850"
    :adaptive="true"
    class="fullDetailModal CEModal modal-scroll-bar"
    name="FullDetailModal2"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <h6 class="text-center font-weight-bold">{{ $t( "Trip Summary" ) }}</h6>

    <div class="row mt-7">
      <div class="col-md-7">
        <TripSummary2 class="mb-5" :summary_data="flight_data"></TripSummary2>
        <v-card
          elevation="4"
          class="mt-5 p-5"
        >
          <div v-if="flight_data.dealLength=='O/W'">
            <div class="d-flex align-items-center justify-content-between" v-if="flight_data.flightsPairs">
              <img :src="'https://images.kiwi.com/airlines/64x64/'+flight_data.flightsPairs[0].to.airline+'.png'" alt="" class="d-block company-logo">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{ flight_data.flightsPairs[0].to.date | moment('D/M') }}
                </p>
                <p class="mb-0 fs12">
                  {{ $t(tWeek(flight_data.flightsPairs[0].to.date)) }}
                </p>
              </div>
              <div class="d-flex align-items-end flex-column">
                <p class="mb-0">
                  <span class="fs12">
                    {{ $t(tWeek(flight_data.flightsPairs[0].to.date)) }}
                  </span>
                  <span class="font-weight-bold">{{ flight_data.flightsPairs[0].to.depTime }}</span>
                </p>
                <div class="fs12">
                  {{ flight_data.flightsPairs[0].to.departureIATA }}
                </div>
              </div>


              <div class="d-flex flex-column align-items-center">
                <p class="fs10 mb-0">{{ flight_data.flightsPairs[0].to.connection !='' ? flight_data.flightsPairs[0].to.connection : $t("Direct flight") }}</p>
                <img src="/assets/img/icons/arrows-h-alt.svg" alt="" style="width: 21px;">
              </div>


              <div class="d-flex align-items-end flex-column">
                <p class="mb-0">
                  <span class="fs12">
                    {{ $t(tWeek(flight_data.flightsPairs[0].to.date)) }}
                  </span>
                  <span class="font-weight-bold">{{ flight_data.flightsPairs[0].to.arrTime }}</span>
                </p>
                <div class="fs12">
                  {{ flight_data.flightsPairs[0].to.arrivalIATA }}
                </div>
              </div>




            </div>
          </div>
          <div v-else>
            <div v-if="flight_data.flightsPairs">
              <div class="d-flex align-items-center justify-content-between">
                <img :src="'https://images.kiwi.com/airlines/64x64/'+flight_data.flightsPairs[0].to.airline+'.png'" alt="" class="d-block company-logo">
                <div>
                  <p class="mb-0 font-weight-bold">
                    {{ flight_data.flightsPairs[0].to.date | moment('D/M') }}
                  </p>
                  <p class="mb-0 fs12">
                    {{ $t(tWeek(flight_data.flightsPairs[0].to.date)) }}
                  </p>
                </div>
                <div class="d-flex align-items-end flex-column">
                  <p class="mb-0">
                    <span class="fs12">
                      {{ $t(tWeek(flight_data.flightsPairs[0].to.date)) }}
                    </span>
                    <span class="font-weight-bold">{{ flight_data.flightsPairs[0].to.depTime }}</span>
                  </p>
                  <div class="fs12">
                    {{ flight_data.flightsPairs[0].to.departureIATA }}
                  </div>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <p class="fs10 mb-0">{{ $t( "Direct flight" ) }}</p>
                  <img src="/assets/img/icons/arrows-h-alt.svg" alt="" style="width: 21px;">
                </div>
                <div class="d-flex align-items-end flex-column">
                  <p class="mb-0">
                    <span class="fs12">
                      {{ $t(tWeek(flight_data.flightsPairs[0].to.date)) }}
                    </span>
                    <span class="font-weight-bold">{{ flight_data.flightsPairs[0].to.arrTime }}</span>
                  </p>
                  <div class="fs12">
                    {{ flight_data.flightsPairs[0].to.arrivalIATA }}
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between my-10">
                <div class="fs14">
                  {{ flight_data.dealLength }} Days 
                </div>
                <div class="border-1 w-80">
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <img :src="'https://images.kiwi.com/airlines/64x64/'+flight_data.flightsPairs[0].from.airline+'.png'" alt="" class="d-block company-logo">
                <div>
                  <p class="mb-0 font-weight-bold">
                    {{ flight_data.flightsPairs[0].from.date | moment('D/M') }}
                  </p>
                  <p class="mb-0 fs12">
                    {{ $t(tWeek(flight_data.flightsPairs[0].from.date)) }}
                  </p>
                </div>
                <div class="d-flex align-items-end flex-column">
                  <p class="mb-0">
                    <span class="fs12">
                      {{ $t(tWeek(flight_data.flightsPairs[0].from.date)) }}
                    </span>
                    <span class="font-weight-bold">{{ flight_data.flightsPairs[0].from.depTime }}</span>
                  </p>
                  <div class="fs12">
                    {{ flight_data.flightsPairs[0].from.departureIATA }}
                  </div>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <p class="fs10 mb-0">{{ $t( "Direct flight" ) }}</p>
                  <img src="/assets/img/icons/arrows-h-alt.svg" alt="" style="width: 21px;">
                </div>
                <div class="d-flex align-items-end flex-column">
                  <p class="mb-0">
                    <span class="fs12">
                      {{ $t(tWeek(flight_data.flightsPairs[0].from.date)) }}
                    </span>
                    <span class="font-weight-bold">{{ flight_data.flightsPairs[0].from.arrTime }}</span>
                  </p>
                  <div class="fs12">
                    {{ flight_data.flightsPairs[0].from.arrivalIATA }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="col-md-5">
        <BaggageInfo v-if="flight_data.flightsPairs" :flight="flight_data.flightsPairs[0]"></BaggageInfo>
        <CovideNotify class="mt-7"></CovideNotify>
        <div class="d-flex align-items-end mt-10">
          <button class="primary-btn primary-btn_sm bg-green6">
            {{ $t( "Book Flight" ) }}
          </button>
          <button class="primary-btn primary-btn_sm bg-grey8" @click="closeModal()">
            {{ $t( "Close" ) }}
          </button>
        </div>
      </div>
    </div>
    <p v-if="flight_data.dealLength!='O/W'" class="fs14 my-10">
      {{ flight_data.dealLength }} nights in {{ flight_data.destinationName }} then <b>depart from Tel_Aviv, Israel</b>
    </p>
  </modal>
</template>

<script>
import  FligntTicket from "../tradeShow/FligntTicket.vue";
import  BaggageInfo from "../tradeShow/BaggageInfo.vue";
import  CovideNotify from "../tradeShow/CovideNotify.vue";
import  TripSummary2 from "../tradeShow/TripSummary2.vue";
import  HotelData from "../tradeShow/HotelData.vue";

export default {
  name: 'FullDetailModal2',
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary2,
    HotelData
  }, 
  data: () => {
    return {
      flight_data: {}
    }
  }, 
  // props: {
  //   flight_data: {
  //     type: Object,
  //     required: true,
  //     default: () => {}
  //   },
  // },
  methods: {
    openModal(deal) {
      this.flight_data = deal;
      this.$modal.show("FullDetailModal2");
    },
    closeModal() {
      this.$modal.hide("FullDetailModal2");
    },
    tWeek(wDay) {
      return this.$moment(wDay).format('ddd');
    },
  }
}
</script>

<style lang="scss">
  .fullDetailModal .vm--modal{
    padding: 20px;
  }

  .company-logo {
    height: 32px;
  }
</style>

